import React from "react";
import { userContext } from "../../context/UserContext";

class AboutUsSegment extends React.Component {
  static contextType = userContext;

  render() {
    return (
      <>
        <div className='section-full welcome-section-outer'>
          <div className='welcome-section-top bg-white p-t80 p-b50'>
            <div className='container'>
              <div className='row d-flex justify-content-center'>
                <div className='col-lg-12 col-md-12 m-b30'>
                  <div
                    dangerouslySetInnerHTML={{
                      __html:
                        this.context &&
                        this.context["pages-content"].aboutUs.page,
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default AboutUsSegment;
