import React, { useEffect } from "react";
import { NavLink } from "react-router-dom";
import { userContext } from "../../context/UserContext";
import _ from "lodash";

function Navigation(props) {
    const context = React.useContext(userContext);
    useEffect(() => {
        function loadScript(src) {
            return new Promise(function (resolve, reject) {
                var script = document.createElement("script");
                script.src = src;
                script.addEventListener("load", function () {
                    resolve();
                });
                script.addEventListener("error", function (e) {
                    reject(e);
                });
                document.body.appendChild(script);
                document.body.removeChild(script);
            });
        }

        loadScript("./assets/js/custom.js");
    }, []);

    return (
        <>
            <div className="nav-animation header-nav navbar-collapse collapse d-flex justify-content-center">
                <ul className=" nav navbar-nav">
                    <li>
                        <NavLink to={""}>
                            {context && context["pages-content"]?.menu?.home}
                        </NavLink>
                    </li>
                    <li>
                        <NavLink to={""}>
                            {context &&
                                context["pages-content"]?.menu?.products}
                        </NavLink>
                        <ul className="sub-menu">
                            {context &&
                                context["products"] &&
                                _.filter(context["products"], (item) => {
                                    return _.includes(
                                        context["pages-content"]?.menu
                                            ?.categories,
                                        item.key
                                    );
                                }).map((item, index) => {
                                    return (
                                        <li key={index}>
                                            <NavLink to={`/${item.key}/`}>
                                                {item?.title}
                                            </NavLink>
                                        </li>
                                    );
                                })}
                        </ul>
                    </li>
                    <li>
                        <NavLink to={""}>
                            {context && context["pages-content"]?.menu?.about}
                        </NavLink>
                        <ul className="sub-menu">
                            <li>
                                <NavLink to={"/about-us"}>
                                    {context &&
                                        context["pages-content"]?.menu?.aboutUs}
                                </NavLink>
                            </li>
                            <li>
                                <NavLink to={"/privacy-policy"}>
                                    {context &&
                                        context["pages-content"]?.menu
                                            ?.privacyPolicy}
                                </NavLink>
                            </li>
                        </ul>
                    </li>
                    <li>
                        <a className="nav-link page-scroll" href="#contactus">
                            {context &&
                                context["pages-content"]?.menu?.contactUs}
                        </a>
                    </li>
                </ul>
            </div>
        </>
    );
}

export default Navigation;
