import React, { useState } from "react";
import Navigation from "../Parts/Navigation";
import { NavLink } from "react-router-dom";
import { userContext } from "../../context/UserContext";

function Header(props) {
    const context = React.useContext(userContext);
    const [isSearchActive, setIsSearchActive] = React.useState(false);
    return (
        <>
            <header className="site-header header-style-1 mobile-sider-drawer-menu">
                {/* SITE Search */}
                <div
                    id="search-toggle-block"
                    style={{ display: isSearchActive ? "block" : "none" }}
                >
                    <div id="search">
                        <form
                            role="search"
                            id="searchform"
                            action="/search"
                            method="get"
                            className="radius-xl"
                        >
                            <div className="input-group">
                                <input
                                    defaultValue=""
                                    className="form-control"
                                    name="q"
                                    type="search"
                                    placeholder="Type to search"
                                />
                                <span className="input-group-append">
                                    <button
                                        type="button"
                                        className="search-btn"
                                    ></button>
                                </span>
                            </div>
                        </form>
                    </div>
                </div>
                <div className="top-bar site-bg-secondry">
                    <div className="container">
                        <div className="d-flex justify-content-between">
                            <div className="wt-topbar-left d-flex flex-wrap align-content-start">
                                <ul className="wt-topbar-info e-p-bx text-white">
                                    <li>
                                        <i className="fa fa-whatsapp" />
                                        {
                                            context["pages-content"]?.header
                                                ?.whatsapp
                                        }
                                    </li>
                                    <li>
                                        <i className="fa fa-phone" />
                                        {
                                            context["pages-content"]?.header
                                                ?.phone
                                        }
                                    </li>
                                    <li>
                                        <i className="fa fa-envelope" />
                                        {
                                            context["pages-content"]?.header
                                                ?.email
                                        }
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="container header-middle clearfix">
                    <div className="logo-header">
                        <div className="logo-header-inner logo-header-one">
                            <NavLink to={"/"}>
                                <img
                                    src="../assets/images/header/logo-line.png"
                                    alt="RITAPAD Printing Pvt. Ltd."
                                />
                            </NavLink>
                        </div>
                    </div>
                    <div className="header-info">
                        <ul>
                            <li>
                                <div className="icon-md icon-md-header">
                                    <span className="icon-cell">
                                        <div className="form-control">
                                            <img
                                                alt="ISO"
                                                src="../assets/images/header/iso.png"
                                            ></img>
                                        </div>
                                    </span>
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>
                <div className="sticky-header main-bar-wraper  navbar-expand-lg">
                    <div className="main-bar">
                        <div className="container clearfix">
                            <img
                                src="assets/images/header/logo.png"
                                alt="Industro"
                                className="logoImg"
                            />
                            <button
                                id="mobile-side-drawer"
                                data-target=".header-nav"
                                data-toggle="collapse"
                                type="button"
                                className="navbar-toggler collapsed"
                            >
                                <span className="sr-only">
                                    Toggle navigation
                                </span>
                                <span className="icon-bar icon-bar-first" />
                                <span className="icon-bar icon-bar-two" />
                                <span className="icon-bar icon-bar-three" />
                            </button>
                            <Navigation />
                        </div>
                    </div>
                </div>
            </header>
        </>
    );
}

export default Header;
