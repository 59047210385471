import React from "react";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import { userContext } from "../../context/UserContext";

const services = [
    {
        image: require("./../../images/printProduct/1.png"),
    },
    {
        image: require("./../../images/printProduct/2.png"),
    },
];

function Services1(props) {
    const context = React.useContext(userContext);
    const options = {
        loop: true,
        autoplay: true,
        nav: false,
        dots: true,
        margin: 0,
        navText: [
            '<i class="fa fa-chevron-left"></i>',
            '<i class="fa fa-chevron-right"></i>',
        ],
        responsive: {
            0: {
                items: 1,
            },
            640: {
                items: 1,
            },
            767: {
                items: 1,
            },
            991: {
                items: 1,
            },
            1366: {
                items: 1,
            },
            1400: {
                items: 1,
            },
        },
    };

    return (
        <>
            <div className="section-full p-t80 p-b70 overlay-wraper bg-no-repeat bg-bottom-left bg-cover">
                <h2 className="text-center">
                    {context && context["pages-content"]?.home?.slider2}
                </h2>
                <div className="overlay-main site-bg-secondry opacity-03" />
                <div className="section-content container-fluid">
                    <OwlCarousel
                        className="owl-carousel gallery-slider2 owl-btn-vertical-center mfp-gallery"
                        {...options}
                    >
                        {services.map((item, index) => (
                            <div key={index} className="item">
                                <div className="project-img-effect-1">
                                    <img src={item.image} alt="" />
                                </div>
                            </div>
                        ))}
                    </OwlCarousel>
                </div>
            </div>
        </>
    );
}

export default Services1;

// class Services1 extends React.Component {
//   static contextType = userContext;

//   render() {
//     const options = {
//       loop: true,
//       autoplay: true,
//       nav: false,
//       dots: true,
//       margin: 0,
//       navText: [
//         '<i class="fa fa-chevron-left"></i>',
//         '<i class="fa fa-chevron-right"></i>',
//       ],
//       responsive: {
//         0: {
//           items: 1,
//         },
//         640: {
//           items: 1,
//         },
//         767: {
//           items: 1,
//         },
//         991: {
//           items: 1,
//         },
//         1366: {
//           items: 1,
//         },
//         1400: {
//           items: 1,
//         },
//       },
//     };

//     return (
//       <>
//         <div className='section-full p-t80 p-b70 overlay-wraper bg-no-repeat bg-bottom-left bg-cover'>
//           <h2 className='text-center'>
//             {this.context && this.context["pages-content"].home.slider2}
//           </h2>
//           <div className='overlay-main site-bg-secondry opacity-03' />
//           <div className='section-content container-fluid'>
//             <OwlCarousel
//               className='owl-carousel gallery-slider2 owl-btn-vertical-center mfp-gallery'
//               {...options}
//             >
//               {services.map((item, index) => (
//                 <div key={index} className='item'>
//                   <div className='project-img-effect-1'>
//                     <img src={item.image.default} alt='' />
//                   </div>
//                 </div>
//               ))}
//             </OwlCarousel>
//           </div>
//         </div>
//       </>
//     );
//   }
// }

// export default Services1;
