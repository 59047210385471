import React from "react";
import { NavLink } from "react-router-dom";
import Switcher from "../Segments/Switcher";
import { userContext } from "../../context/UserContext";
import _ from "lodash";
var bgimage = require("./../../images/background/footer-bg.png");
var bgimg = require("./../../images/background/bg-map2.png");

function Footer(props) {
    const context = React.useContext(userContext);
    console.log(context);
    const [state, setState] = React.useState({});

    const getEmailBody = (initial) => {
        const items = {
            url: encodeURIComponent(window.location.href),
            ...this.state,
        };
        const link = Object.keys(items).reduce((a, i) => {
            return `${a}\n${i} ${items[i]}`;
        }, initial);
        return encodeURIComponent(link);
    };
    const updateFooterLogo = (updatedlogo) => {
        this.currentstate.logo = updatedlogo.default;
    };

    const onInputchange = (event) => {
        setState({
            [event.target.name]: event.target.value,
        });
    };
    return (
        <>
            <div className="page-content" id="contactus">
                <div
                    className="section-full  p-t80 p-b50 bg-cover"
                    style={{ backgroundColor: "#6c757d" }}
                >
                    <div className="section-content">
                        <div className="container">
                            <div className="contact-one">
                                {/* CONTACT FORM*/}
                                <div className="row  d-flex justify-content-center flex-wrap">
                                    <div className="col-lg-6 col-md-6 m-b30">
                                        <form
                                            className="cons-contact-form"
                                            method="post"
                                            action="#"
                                        >
                                            {/* TITLE START */}
                                            <div className="section-head left wt-small-separator-outer">
                                                <div className="wt-small-separator">
                                                    <div className="sep-leaf-left" />
                                                    <div>
                                                        {context &&
                                                            context[
                                                                "pages-content"
                                                            ]?.footer
                                                                .contactForm}
                                                    </div>
                                                </div>
                                                <h2>
                                                    {context &&
                                                        context["pages-content"]
                                                            ?.footer.getInTouch}
                                                </h2>
                                            </div>
                                            {/* TITLE END */}
                                            <div className="row">
                                                <div className="col-lg-12 col-md-12">
                                                    <div className="form-group">
                                                        <input
                                                            name="name"
                                                            type="text"
                                                            required
                                                            className="form-control"
                                                            placeholder="Name"
                                                            value={state?.name}
                                                            onChange={
                                                                onInputchange
                                                            }
                                                        />
                                                    </div>
                                                </div>
                                                <div className="col-lg-12 col-md-12">
                                                    <div className="form-group">
                                                        <input
                                                            name="email"
                                                            type="text"
                                                            className="form-control"
                                                            required
                                                            placeholder="Email"
                                                            value={state?.email}
                                                            onChange={
                                                                onInputchange
                                                            }
                                                        />
                                                    </div>
                                                </div>
                                                <div className="col-lg-12 col-md-12">
                                                    <div className="form-group">
                                                        <input
                                                            name="phone"
                                                            type="text"
                                                            className="form-control"
                                                            required
                                                            placeholder="Phone"
                                                            value={state?.phone}
                                                            onChange={
                                                                onInputchange
                                                            }
                                                        />
                                                    </div>
                                                </div>
                                                <div className="col-lg-12 col-md-12">
                                                    <div className="form-group">
                                                        <input
                                                            name="subject"
                                                            type="text"
                                                            className="form-control"
                                                            required
                                                            placeholder="Subject"
                                                            value={
                                                                state?.subject
                                                            }
                                                            onChange={
                                                                onInputchange
                                                            }
                                                        />
                                                    </div>
                                                </div>
                                                <div className="col-md-12">
                                                    <div className="form-group">
                                                        <textarea
                                                            name="message"
                                                            className="form-control"
                                                            rows={4}
                                                            placeholder="Message"
                                                            value={
                                                                state?.message
                                                            }
                                                            onChange={
                                                                onInputchange
                                                            }
                                                        />
                                                    </div>
                                                </div>
                                                <div className="col-md-12">
                                                    <div
                                                        className="site-button site-btn-effect"
                                                        onClick={(e) => {
                                                            const link = `mailto:info@ritapad.com?subject=${
                                                                state?.subject
                                                            }&body=${getEmailBody(
                                                                context &&
                                                                    context[
                                                                        "pages-content"
                                                                    ]?.footer
                                                                        ?.emailCoverNote
                                                            )}`;
                                                            window.location.href =
                                                                link;
                                                            e.preventDefault();
                                                        }}
                                                    >
                                                        {context &&
                                                            context[
                                                                "pages-content"
                                                            ]?.footer
                                                                ?.submitNow}
                                                    </div>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                    <div className="col-lg-6 col-md-6 m-b30">
                                        <div className="contact-info">
                                            <div className="contact-info-inner">
                                                {/* TITLE START*/}
                                                <div className="section-head left wt-small-separator-outer">
                                                    <div className="wt-small-separator">
                                                        <div className="sep-leaf-left" />
                                                        <div>
                                                            {context &&
                                                                context[
                                                                    "pages-content"
                                                                ]?.footer
                                                                    ?.contactInfo}
                                                        </div>
                                                    </div>
                                                    <h2>
                                                        {context &&
                                                            context[
                                                                "pages-content"
                                                            ]?.footer
                                                                ?.ourFullInfo}
                                                    </h2>
                                                </div>
                                                {/* TITLE END*/}
                                                <div
                                                    className="contact-info-section"
                                                    style={{
                                                        backgroundImage:
                                                            "url(" +
                                                            bgimg.default +
                                                            ")",
                                                    }}
                                                >
                                                    <div className="wt-icon-box-wraper left m-b30">
                                                        <div className="icon-content">
                                                            <h3 className="m-t0 site-text-primary">
                                                                {context &&
                                                                    context[
                                                                        "pages-content"
                                                                    ]?.footer
                                                                        ?.phoneHeader}
                                                            </h3>
                                                            <p>
                                                                {context &&
                                                                    context[
                                                                        "pages-content"
                                                                    ]?.footer
                                                                        ?.phone}
                                                            </p>
                                                        </div>
                                                    </div>
                                                    <div className="wt-icon-box-wraper left m-b30">
                                                        <div className="icon-content">
                                                            <h3 className="m-t0 site-text-primary">
                                                                {context &&
                                                                    context[
                                                                        "pages-content"
                                                                    ]?.footer
                                                                        ?.emailHeader}
                                                            </h3>
                                                            <p>
                                                                {context &&
                                                                    context[
                                                                        "pages-content"
                                                                    ]?.footer
                                                                        ?.email}
                                                            </p>
                                                        </div>
                                                    </div>
                                                    <div className="wt-icon-box-wraper left m-b30">
                                                        <div className="icon-content">
                                                            <h3 className="m-t0 site-text-primary">
                                                                {context.footer &&
                                                                    context
                                                                        .footer[
                                                                        "pages-content"
                                                                    ]?.footer
                                                                        ?.addressHeader}
                                                            </h3>
                                                            <p>
                                                                {context &&
                                                                    context[
                                                                        "pages-content"
                                                                    ]?.footer
                                                                        ?.address}
                                                            </p>
                                                        </div>
                                                    </div>
                                                    <div className="wt-icon-box-wraper left">
                                                        <div className="icon-content">
                                                            <h3 className="m-t0 site-text-primary">
                                                                {context &&
                                                                    context[
                                                                        "pages-content"
                                                                    ]?.footer
                                                                        ?.timeHeader}
                                                            </h3>
                                                            <div
                                                                dangerouslySetInnerHTML={{
                                                                    __html:
                                                                        context &&
                                                                        context[
                                                                            "pages-content"
                                                                        ]
                                                                            ?.footer
                                                                            ?.time,
                                                                }}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <hr />

            <footer className="site-footer footer-large footer-dark text-white footer-style1">
                {/* FOOTER BLOCKES START */}
                <div
                    className="footer-top bg-no-repeat bg-bottom-right"
                    style={{
                        backgroundImage: "url(" + bgimage.default + ")",
                    }}
                >
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-5 col-md-12 col-sm-12">
                                <div className="footer-h-left">
                                    <div className="widget widget_about">
                                        <div className="logo-footer clearfix">
                                            <NavLink to={"./"}>
                                                <img
                                                    src="assets/images/header/logo.png"
                                                    alt=""
                                                />
                                            </NavLink>
                                        </div>

                                        <div
                                            dangerouslySetInnerHTML={{
                                                __html: context["pages-content"]
                                                    ?.footer?.page,
                                            }}
                                        />
                                    </div>
                                    <div className="widget recent-posts-entry">
                                        <ul className="widget_address">
                                            <li>
                                                <i className="fa fa-map-marker" />
                                                {
                                                    context["pages-content"]
                                                        ?.footer?.address
                                                }
                                            </li>
                                            <li>
                                                <i className="fa fa-envelope" />
                                                {
                                                    context["pages-content"]
                                                        ?.footer.email
                                                }
                                            </li>
                                            <li>
                                                <i className="fa fa-phone" />
                                                {
                                                    context["pages-content"]
                                                        ?.footer.phone
                                                }
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-7 col-md-12 col-sm-12">
                                <div className="row footer-h-right">
                                    <div className="col-lg-5 col-md-4">
                                        <div className="widget widget_services">
                                            <h3 className="widget-title">
                                                {
                                                    context["pages-content"]
                                                        ?.footer.about
                                                }
                                            </h3>
                                            <ul>
                                                <li>
                                                    <NavLink to={"/about-us"}>
                                                        {
                                                            context[
                                                                "pages-content"
                                                            ]?.menu.aboutUs
                                                        }
                                                    </NavLink>
                                                </li>

                                                <li>
                                                    <NavLink
                                                        to={"/privacy-policy"}
                                                    >
                                                        {
                                                            context[
                                                                "pages-content"
                                                            ]?.menu
                                                                .privacyPolicy
                                                        }
                                                    </NavLink>
                                                </li>
                                                <li>
                                                    <NavLink
                                                        to={"/#contact-us"}
                                                    >
                                                        {
                                                            context[
                                                                "pages-content"
                                                            ]?.menu.contactUs
                                                        }
                                                    </NavLink>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                    <div className="col-lg-7 col-md-8">
                                        <div className="widget widget_services">
                                            <h3 className="widget-title">
                                                {
                                                    context["pages-content"]
                                                        ?.footer.products
                                                }
                                            </h3>

                                            <ul>
                                                {context &&
                                                    context["products"] &&
                                                    _.filter(
                                                        context["products"],
                                                        (item) => {
                                                            return _.includes(
                                                                context[
                                                                    "pages-content"
                                                                ]?.menu
                                                                    ?.categories,
                                                                item.key
                                                            );
                                                        }
                                                    ).map((item, index) => {
                                                        return (
                                                            <li key={index}>
                                                                <NavLink
                                                                    to={`/${item.key}/`}
                                                                >
                                                                    {item.title}
                                                                </NavLink>
                                                            </li>
                                                        );
                                                    })}
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* FOOTER COPYRIGHT */}
                <div className="footer-bottom">
                    <div className="container">
                        <div className="wt-footer-bot-left d-flex justify-content-between">
                            <span className="copyrights-text">
                                {"Copyright © 2023 "}
                                <span className="site-text-primary">
                                    {"www.gluedtomatoes.com"}
                                </span>
                            </span>
                            <ul className="copyrights-nav">
                                <li>
                                    <NavLink to={"/privacy-policy"}>
                                        {
                                            context["pages-content"]?.menu
                                                .privacyPolicy
                                        }
                                    </NavLink>
                                </li>
                                <li>
                                    &nbsp;
                                    <NavLink to={"#contactus"}>
                                        {
                                            context["pages-content"]?.menu
                                                .contactUs
                                        }
                                    </NavLink>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </footer>
            <Switcher updateFooterLogo={updateFooterLogo} />
        </>
    );
}

export default Footer;
