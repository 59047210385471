import React, { useEffect, useState } from "react";
import Components from "./components/Components";
import ScrolToTop from "./components/Segments/ScrolToTop";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import Loader from "./components/Segments/Loader";
import { userContext } from "./context/UserContext";
import ScrollToTop from "./components/ScrollToTop";
import Test from "./components/Pages/Test";
import Home from "./components/Pages/Home";
import Composite from "./components/Pages/Composite";
import AboutUs from "./components/Pages/AboutUs";
import PrivacyPolicy from "./components/Pages/PrivacyPolicy";
import Error404 from "./components/Pages/Error404";

const App = () => {
    const [catalog, setCatalog] = useState([]);
    useEffect(() => {
        (async () => {
            const res = await fetch("../ApplicationData.json");
            const data = await res.json();
            setCatalog(data);
        })();
    }, []);

    return (
        <userContext.Provider value={catalog}>
            <div className="App">
                <script src="https://code.jquery.com/jquery-3.1.0.min.js"></script>
                <BrowserRouter basename="/">
                    <div className="page-wraper">
                        <ScrollToTop />
                        <Switch>
                            <Route path="/test" exact component={Test} />
                            <Route path="/" exact component={Home} />
                            <Route path="/about-us" exact component={AboutUs} />
                            <Route
                                path="/privacy-policy"
                                exact
                                component={PrivacyPolicy}
                            />
                            <Route path="/:key/*" component={Composite} />

                            <Route component={Error404} />
                        </Switch>
                    </div>
                </BrowserRouter>
            </div>
        </userContext.Provider>
    );
};

export default App;
