import React from "react";
import { NavLink } from "react-router-dom";
import "./ProductsSegment.css";

class ProductsSegment extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            filters: [],
            productsList: [],
        };
    }

    componentDidMount() {
        const machines = this.props.data;
        if (machines) this.getFilters(machines.children);
        if (machines)
            this.getProductsList(machines.children, this.props.allData);
    }

    componentWillReceiveProps(newProps) {
        const machines = newProps.data;
        if (machines) this.getFilters(machines.children);
        if (machines)
            this.getProductsList(machines.children, this.props.allData);
    }

    getFilters = async (data) => {
        let filterItem = [];
        for (const item in data) {
            if (data[item] === "All")
                filterItem.push({ label: data[item], filter: "*" });
            else
                filterItem.push({
                    label: data[item],
                    filter: `.cat-${data[item]}`,
                });
        }
        this.setState({ filters: filterItem });
        console.log("filterItem=>", filterItem);
    };

    getProductsList = (filters, products) => {
        let productsList = [];
        filters.forEach((smi) => {
            if (smi !== "All") {
                const items = products.filter((x) => x.key === smi)[0];
                if (items) {
                    const itemLists = items.children;
                    itemLists.forEach((item) => {
                        const detail = products.filter(
                            (i) => i.key === item
                        )[0];
                        if (detail) {
                            detail.filter = `cat-${smi}`;
                            productsList.push(detail);
                        }
                    });
                }
            }
        });
        this.setState({ productsList: productsList });
    };

    render() {
        return (
            <>
                <div className="section-full p-t80 p-b50 bg-white">
                    <div className="container">
                        {/* PAGINATION START */}
                        <div className="filter-wrap work-grid p-b30 text-center">
                            <ul className="masonry-filter link-style ">
                                {this.state.filters.map((item, index) => (
                                    <li key={index}>
                                        <NavLink
                                            to={"#"}
                                            data-filter={item.filter}
                                        >
                                            {item.label}
                                        </NavLink>
                                    </li>
                                ))}
                            </ul>
                        </div>
                        {/* PAGINATION END */}
                        {/* GALLERY CONTENT START */}
                        <div className="masonry-wrap mfp-gallery  row clearfix d-flex justify-content-center flex-wrap">
                            {this.state.productsList.map((detail, index) => {
                                return (
                                    <div
                                        key={index}
                                        className={`${detail.filter} masonry-item col-lg-4 col-md-6 col-sm-6 m-b30`}
                                    >
                                        <div className="div-box-shodow">
                                            <img
                                                width={300}
                                                height={200}
                                                src={
                                                    detail.gallery.length > 0
                                                        ? detail.gallery[0]
                                                        : "assets/images/header/logo.png"
                                                }
                                                alt=""
                                                onError={(e) => {
                                                    e.target.src =
                                                        "../assets/images/machine/image-coming-soon.png";
                                                }}
                                            />
                                            <div className="wt-info">
                                                <h3 className="wt-tilte m-b10 m-t0">
                                                    {detail.key}
                                                </h3>
                                                {detail?.description && (
                                                    <>
                                                        <p
                                                            dangerouslySetInnerHTML={{
                                                                __html: detail?.shortDescription,
                                                            }}
                                                        ></p>
                                                        <NavLink
                                                            to={`/product-details/${detail.key}`}
                                                            className="site-button-link"
                                                        >
                                                            Read More
                                                        </NavLink>
                                                    </>
                                                )}
                                            </div>
                                            {/* <a href={detail.image} className='mfp-link'>
                            <i className='fa fa-search-plus' />
                          </a> */}
                                        </div>
                                    </div>
                                );
                            })}
                        </div>
                        {/* GALLERY CONTENT END */}
                    </div>
                </div>
            </>
        );
    }
}

export default ProductsSegment;
