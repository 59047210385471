import React from "react";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import { userContext } from "../../context/UserContext";

const projects = [
    {
        image: require("./../../images/customer/customer-b1.png"),
    },
    {
        image: require("./../../images/customer/customer-b2.png"),
    },
];

function Testimonials1(props) {
    const context = React.useContext(userContext);
    const options = {
        loop: true,
        autoplay: true,
        nav: false,
        dots: true,
        margin: 0,
        navText: [
            '<i class="fa fa-chevron-left"></i>',
            '<i class="fa fa-chevron-right"></i>',
        ],
        responsive: {
            0: {
                items: 1,
            },
            640: {
                items: 2,
            },
            767: {
                items: 2,
            },
            991: {
                items: 2,
            },
            1024: {
                items: 2,
            },
        },
    };
    return (
        <>
            <div className="section-full p-t80 p-b80 bg-gray">
                <div className="section-content container-fluid">
                    <h2 className="text-center">
                        {context && context["pages-content"]?.home?.slider3}
                    </h2>
                    <OwlCarousel
                        className="owl-carousel gallery-slider2 owl-btn-vertical-center mfp-gallery"
                        {...options}
                    >
                        {projects.map(
                            (item, index) => (
                                console.log(item),
                                (
                                    <div key={index} className="item">
                                        <div className="project-img-effect-1">
                                            <img src={item.image} alt="" />
                                        </div>
                                    </div>
                                )
                            )
                        )}
                    </OwlCarousel>
                </div>
            </div>
        </>
    );
}

export default Testimonials1;
// class Testimonials1 extends React.Component {
//   static contextType = userContext;
//   render() {
//     const options = {
//       loop: true,
//       autoplay: true,
//       nav: false,
//       dots: true,
//       margin: 0,
//       navText: [
//         '<i class="fa fa-chevron-left"></i>',
//         '<i class="fa fa-chevron-right"></i>',
//       ],
//       responsive: {
//         0: {
//           items: 1,
//         },
//         640: {
//           items: 2,
//         },
//         767: {
//           items: 2,
//         },
//         991: {
//           items: 2,
//         },
//         1024: {
//           items: 2,
//         },
//       },
//     };
//     return (
//       <>
//         <div className='section-full p-t80 p-b80 bg-gray'>
//           <div className='section-content container-fluid'>

//           <h2 className='text-center'>
//             {this.context && this.context["pages-content"].home.slider3}
//           </h2>

//             <OwlCarousel
//               className='owl-carousel gallery-slider2 owl-btn-vertical-center mfp-gallery'
//               {...options}
//             >
//               {projects.map((item, index) => (
//                 <div key={index} className='item'>
//                   <div className='project-img-effect-1'>
//                     <img src={item.image.default} alt='' />
//                   </div>
//                 </div>
//               ))}
//             </OwlCarousel>
//           </div>
//         </div>
//       </>
//     );
//   }
// }

// export default Testimonials1;
