import React, { useEffect } from "react";
import { userContext } from "../../context/UserContext";

function HeroVideo(props) {
    const context = React.useContext(userContext);

    return (
        <>
            {context["pages-content"]?.home?.videourl && (
                <>
                    <video
                        controlsList="nofullscreen nodownload"
                        autoPlay
                        muted
                        loop
                        src="./assets/video/video1.mp4"
                    ></video>

                    <div class="overlay">
                        <h2 className="text-white">
                            {context["pages-content"]?.home?.slider1}
                        </h2>
                    </div>
                </>
            )}
        </>
    );
}

export default HeroVideo;
