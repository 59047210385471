import React from "react";
import Header from "../Parts/Header";
import Footer from "../Parts/Footer";
import Banner from "../Segments/Banner";
import AboutUsSegment from "../Segments/AboutUsSegment";
import Services13 from "../Segments/Services13";

function AboutUs(props) {
    return (
        <>
            <Header />
            <div className="page-content">
                <Banner title="About Us" pagename="About Us" />
                <AboutUsSegment />
                <Services13 />
            </div>
            <Footer />
        </>
    );
}

export default AboutUs;
