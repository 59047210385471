import React from "react";
import Header from "../Parts/Header";
import Footer from "../Parts/Footer";
import Banner from "../Segments/Banner";
import ProductsSegment from "../Segments/ProductsSegment";

class Products extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            productKey: null,
            product: null,
            allData: null,
        };
    }

    componentDidMount() {
        const params = new URLSearchParams(window.location.search);
        const key = params.get("key");
        this.getProducts(key);
    }

    getProducts = async (key) => {
        const res = await fetch("./ApplicationData.json");
        const data = await res.json();

        const product =
            data &&
            data["products"] &&
            data["products"].filter((x) => x.key === key)[0];
        this.setState({ allData: data });
        this.setState({ productKey: key });
        this.setState({ product: product });
    };

    componentWillReceiveProps(newProps) {
        const params = new URLSearchParams(window.location.search);
        const key = params.get("key");
        if (this.state.key !== key) this.getProducts(key);
    }

    render() {
        return (
            <>
                <Header />
                <div className="page-content">
                    {/* INNER PAGE BANNER */}
                    <Banner
                        title={this.state.product && this.state.product.name}
                        pagename={this.state.product && this.state.product.name}
                        bgimage={this.state.product && this.state.product.image}
                    />
                    {/* INNER PAGE BANNER END */}
                    {this.state.product && (
                        <ProductsSegment
                            key={this.state.key}
                            data={this.state.product}
                            allData={
                                this.state.allData &&
                                this.state.allData["products"]
                            }
                        />
                    )}
                </div>
                <div>
                    <table
                        width="100%"
                        height={0}
                        border={0}
                        align="center"
                        cellPadding={2}
                        cellSpacing={2}
                    >
                        <tbody>
                            <tr>
                                <td valign="top">
                                    <table
                                        width="100%"
                                        align="center"
                                        cellPadding={0}
                                        cellSpacing={0}
                                    >
                                        <tbody>
                                            <tr valign="top">
                                                <td colSpan={5}>
                                                    <table
                                                        width="100%"
                                                        border={0}
                                                        cellSpacing={0}
                                                        cellPadding={0}
                                                    >
                                                        <tbody>
                                                            <tr>
                                                                <td
                                                                    width="41%"
                                                                    valign="middle"
                                                                    className="sub_head01"
                                                                >
                                                                    <div align="left">
                                                                        Machine
                                                                        Selection
                                                                        (Standard
                                                                        Machine)
                                                                    </div>
                                                                </td>
                                                                <td
                                                                    width="59%"
                                                                    valign="top"
                                                                    className="sub02"
                                                                >
                                                                    &nbsp;
                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td colSpan={5} valign="bottom">
                                                    &nbsp;
                                                </td>
                                            </tr>
                                            <tr className="text">
                                                <td valign="bottom" width={72}>
                                                    {" "}
                                                    <b>S. NO.</b>{" "}
                                                </td>
                                                <td valign="bottom" width={132}>
                                                    {" "}
                                                    <b>PRINT AREA </b>
                                                </td>
                                                <td valign="bottom" width={96}>
                                                    <b>NO.OF COLOUR</b>{" "}
                                                </td>
                                                <td valign="bottom" width={72}>
                                                    <b>TYPE </b>
                                                </td>
                                                <td valign="bottom" width={109}>
                                                    {" "}
                                                    <b>MODEL </b>
                                                </td>
                                            </tr>
                                            <tr className="text">
                                                <td valign="bottom">&nbsp;</td>
                                                <td valign="bottom">&nbsp;</td>
                                                <td valign="bottom">&nbsp;</td>
                                                <td valign="bottom">&nbsp;</td>
                                                <td valign="bottom">&nbsp;</td>
                                            </tr>
                                            <tr className="text">
                                                <td valign="bottom" width={72}>
                                                    1{" "}
                                                </td>
                                                <td valign="bottom" width={132}>
                                                    55mm{" "}
                                                </td>
                                                <td valign="bottom" width={96}>
                                                    single
                                                </td>
                                                <td valign="bottom" width={72}>
                                                    closed{" "}
                                                </td>
                                                <td valign="bottom" width={109}>
                                                    <a
                                                        href="#"
                                                        onclick="MM_openBrWindow('mtm/mtm_60gf.html','','width=500,height=560')"
                                                    >
                                                        MTM 60 GF{" "}
                                                    </a>
                                                </td>
                                            </tr>
                                            <tr className="text">
                                                <td valign="bottom" width={72}>
                                                    2{" "}
                                                </td>
                                                <td valign="bottom" width={132}>
                                                    55mm{" "}
                                                </td>
                                                <td valign="bottom" width={96}>
                                                    double{" "}
                                                </td>
                                                <td valign="bottom" width={72}>
                                                    open{" "}
                                                </td>
                                                <td valign="bottom" width={109}>
                                                    <a
                                                        href="#"
                                                        onclick="MM_openBrWindow('mtm/MTM-150D.htm','','width=500,height=600')"
                                                    >
                                                        MTM 150 D{" "}
                                                    </a>
                                                </td>
                                            </tr>
                                            <tr className="text">
                                                <td valign="bottom" width={72}>
                                                    3{" "}
                                                </td>
                                                <td valign="bottom" width={132}>
                                                    55mm{" "}
                                                </td>
                                                <td valign="bottom" width={96}>
                                                    double{" "}
                                                </td>
                                                <td valign="bottom" width={72}>
                                                    closed{" "}
                                                </td>
                                                <td valign="bottom" width={109}>
                                                    <a
                                                        href="#"
                                                        onclick="MM_openBrWindow('mtm/MTM-150DC-GF.htm','','width=500,height=600')"
                                                    >
                                                        MTM 150 D GF
                                                    </a>{" "}
                                                </td>
                                            </tr>
                                            <tr className="text">
                                                <td valign="bottom" width={72}>
                                                    4{" "}
                                                </td>
                                                <td valign="bottom" width={132}>
                                                    85mm{" "}
                                                </td>
                                                <td valign="bottom" width={96}>
                                                    single{" "}
                                                </td>
                                                <td valign="bottom" width={72}>
                                                    open{" "}
                                                </td>
                                                <td valign="bottom" width={109}>
                                                    <a
                                                        href="#"
                                                        onclick="MM_openBrWindow('mtm/MTM-100.htm','','width=500,height=600')"
                                                    >
                                                        MTM 100
                                                    </a>{" "}
                                                </td>
                                            </tr>
                                            <tr className="text">
                                                <td valign="bottom" width={72}>
                                                    5{" "}
                                                </td>
                                                <td valign="bottom" width={132}>
                                                    85mm
                                                </td>
                                                <td valign="bottom" width={96}>
                                                    single{" "}
                                                </td>
                                                <td valign="bottom" width={72}>
                                                    <p>closed </p>
                                                </td>
                                                <td valign="bottom" width={109}>
                                                    <p>
                                                        <a
                                                            href="#"
                                                            onclick="MM_openBrWindow('mtm/MTM-100GF.htm','','width=500,height=625')"
                                                        >
                                                            MTM 100 GF{" "}
                                                        </a>
                                                    </p>
                                                </td>
                                            </tr>
                                            <tr className="text">
                                                <td valign="bottom" width={72}>
                                                    6{" "}
                                                </td>
                                                <td valign="bottom" width={132}>
                                                    85mm{" "}
                                                </td>
                                                <td valign="bottom" width={96}>
                                                    double{" "}
                                                </td>
                                                <td valign="bottom" width={72}>
                                                    open{" "}
                                                </td>
                                                <td valign="bottom" width={109}>
                                                    <a
                                                        href="#"
                                                        onclick="MM_openBrWindow('mtm/MLM-100D.htm','','width=500,height=475')"
                                                    >
                                                        MLM 100D{" "}
                                                    </a>
                                                </td>
                                            </tr>
                                            <tr className="text">
                                                <td valign="bottom" width={72}>
                                                    7
                                                </td>
                                                <td valign="bottom" width={132}>
                                                    85mm{" "}
                                                </td>
                                                <td valign="bottom" width={96}>
                                                    double
                                                </td>
                                                <td valign="bottom" width={72}>
                                                    closed{" "}
                                                </td>
                                                <td valign="bottom" width={109}>
                                                    <a
                                                        href="#"
                                                        onclick="MM_openBrWindow('mtm/MLM-100D GF Closed Cup.htm','','width=500,height=600')"
                                                    >
                                                        MLM 100D GF{" "}
                                                    </a>
                                                </td>
                                            </tr>
                                            <tr className="text">
                                                <td valign="bottom" width={72}>
                                                    8
                                                </td>
                                                <td valign="bottom" width={132}>
                                                    85mm{" "}
                                                </td>
                                                <td valign="bottom" width={96}>
                                                    three{" "}
                                                </td>
                                                <td valign="bottom" width={72}>
                                                    open{" "}
                                                </td>
                                                <td valign="bottom" width={109}>
                                                    <a
                                                        href="#"
                                                        onclick="MM_openBrWindow('mtm/MLM-100T.htm','','width=500,height=525')"
                                                    >
                                                        MLM 100T{" "}
                                                    </a>
                                                </td>
                                            </tr>
                                            <tr className="text">
                                                <td valign="bottom" width={72}>
                                                    9
                                                </td>
                                                <td valign="bottom" width={132}>
                                                    85mm{" "}
                                                </td>
                                                <td valign="bottom" width={96}>
                                                    three
                                                </td>
                                                <td valign="bottom" width={72}>
                                                    closed{" "}
                                                </td>
                                                <td valign="bottom" width={109}>
                                                    <a
                                                        href="#"
                                                        onclick="MM_openBrWindow('mtm/MLM-100 T - GF Closed Cup.htm','','width=500,height=625')"
                                                    >
                                                        MLM 100T GF
                                                    </a>{" "}
                                                </td>
                                            </tr>
                                            <tr className="text">
                                                <td valign="bottom" width={72}>
                                                    10
                                                </td>
                                                <td valign="bottom" width={132}>
                                                    85mm{" "}
                                                </td>
                                                <td valign="bottom" width={96}>
                                                    four{" "}
                                                </td>
                                                <td valign="bottom" width={72}>
                                                    open{" "}
                                                </td>
                                                <td valign="bottom" width={109}>
                                                    <a
                                                        href="#"
                                                        onclick="MM_openBrWindow('mtm/mlm100-f.html','','width=500,height=600')"
                                                    >
                                                        MLM 100F
                                                    </a>{" "}
                                                </td>
                                            </tr>
                                            <tr className="text">
                                                <td valign="bottom" width={72}>
                                                    11
                                                </td>
                                                <td valign="bottom" width={132}>
                                                    85mm{" "}
                                                </td>
                                                <td valign="bottom" width={96}>
                                                    four{" "}
                                                </td>
                                                <td valign="bottom" width={72}>
                                                    closed{" "}
                                                </td>
                                                <td valign="bottom" width={109}>
                                                    <a
                                                        href="#"
                                                        onclick="MM_openBrWindow('mtm/MLM-100 F-GF.htm','','width=500,height=600')"
                                                    >
                                                        MLM 100 F GF{" "}
                                                    </a>
                                                </td>
                                            </tr>
                                            <tr className="text">
                                                <td valign="bottom" width={72}>
                                                    12
                                                </td>
                                                <td valign="bottom" width={132}>
                                                    125mm{" "}
                                                </td>
                                                <td valign="bottom" width={96}>
                                                    single{" "}
                                                </td>
                                                <td valign="bottom" width={72}>
                                                    open{" "}
                                                </td>
                                                <td valign="bottom" width={109}>
                                                    <a
                                                        href="#"
                                                        onclick="MM_openBrWindow('mtm/MTM-150.htm','','width=500,height=600')"
                                                    >
                                                        MTM 150{" "}
                                                    </a>
                                                </td>
                                            </tr>
                                            <tr className="text">
                                                <td valign="bottom" width={72}>
                                                    13
                                                </td>
                                                <td valign="bottom" width={132}>
                                                    125mm
                                                </td>
                                                <td valign="bottom" width={96}>
                                                    three{" "}
                                                </td>
                                                <td valign="bottom" width={72}>
                                                    open{" "}
                                                </td>
                                                <td valign="bottom" width={109}>
                                                    <a
                                                        href="#"
                                                        onclick="MM_openBrWindow('mtm/MLM-150 T.htm','','width=500,height=450')"
                                                    >
                                                        MLM 150T{" "}
                                                    </a>
                                                </td>
                                            </tr>
                                            <tr className="text">
                                                <td valign="bottom" width={72}>
                                                    14
                                                </td>
                                                <td valign="bottom" width={132}>
                                                    350x135mm{" "}
                                                </td>
                                                <td valign="bottom" width={96}>
                                                    <p>single </p>
                                                </td>
                                                <td valign="bottom" width={72}>
                                                    <p>open</p>
                                                </td>
                                                <td valign="bottom" width={109}>
                                                    <a
                                                        href="#"
                                                        onclick="MM_openBrWindow('mtm/MLM-400S.htm','','width=500,height=450')"
                                                    >
                                                        MLM 400S{" "}
                                                    </a>
                                                </td>
                                            </tr>
                                            <tr className="text">
                                                <td valign="bottom" width={72}>
                                                    15
                                                </td>
                                                <td valign="bottom" width={132}>
                                                    150x100mm
                                                </td>
                                                <td valign="bottom" width={96}>
                                                    single{" "}
                                                </td>
                                                <td valign="bottom" width={72}>
                                                    open{" "}
                                                </td>
                                                <td valign="bottom" width={109}>
                                                    <a
                                                        href="#"
                                                        onclick="MM_openBrWindow('mtm/MLM-180S.htm','','width=500,height=450')"
                                                    >
                                                        MLM 180S{" "}
                                                    </a>
                                                </td>
                                            </tr>
                                            <tr className="text">
                                                <td valign="bottom" width={72}>
                                                    16
                                                </td>
                                                <td valign="bottom" width={132}>
                                                    180x70mm{" "}
                                                </td>
                                                <td valign="bottom" width={96}>
                                                    single{" "}
                                                </td>
                                                <td valign="bottom" width={72}>
                                                    open{" "}
                                                </td>
                                                <td valign="bottom" width={109}>
                                                    <a
                                                        href="#"
                                                        onclick="MM_openBrWindow('mtm/MLM-210S.htm','','width=500,height=450')"
                                                    >
                                                        MLM 210S{" "}
                                                    </a>
                                                </td>
                                            </tr>
                                            <tr className="text">
                                                <td valign="bottom" width={72}>
                                                    17
                                                </td>
                                                <td valign="bottom" width={132}>
                                                    470x100mm{" "}
                                                </td>
                                                <td valign="bottom" width={96}>
                                                    single
                                                </td>
                                                <td valign="bottom" width={72}>
                                                    open{" "}
                                                </td>
                                                <td valign="bottom" width={109}>
                                                    <a
                                                        href="#"
                                                        onclick="MM_openBrWindow('mtm/MLM-520S.htm','','width=500,height=450')"
                                                    >
                                                        MLM 520S{" "}
                                                    </a>
                                                </td>
                                            </tr>
                                            <tr className="text">
                                                <td valign="bottom" width={72}>
                                                    18
                                                </td>
                                                <td valign="bottom" width={132}>
                                                    180x70mm{" "}
                                                </td>
                                                <td valign="bottom" width={96}>
                                                    double{" "}
                                                </td>
                                                <td valign="bottom" width={72}>
                                                    open{" "}
                                                </td>
                                                <td valign="bottom" width={109}>
                                                    <a
                                                        href="#"
                                                        onclick="MM_openBrWindow('mtm/MLM-210D.htm','','width=500,height=530')"
                                                    >
                                                        MLM 210D{" "}
                                                    </a>
                                                </td>
                                            </tr>
                                            <tr className="text">
                                                <td valign="bottom" width={72}>
                                                    19
                                                </td>
                                                <td valign="bottom" width={132}>
                                                    150x100mm{" "}
                                                </td>
                                                <td valign="bottom" width={96}>
                                                    single{" "}
                                                </td>
                                                <td valign="bottom" width={72}>
                                                    <p>open </p>
                                                </td>
                                                <td valign="bottom" width={109}>
                                                    <a
                                                        href="#"
                                                        onclick="MM_openBrWindow('mtm/MLM-180D.htm','','width=500,height=450')"
                                                    >
                                                        MLM 180D{" "}
                                                    </a>
                                                </td>
                                            </tr>
                                            <tr className="text">
                                                <td valign="bottom" width={72}>
                                                    20
                                                </td>
                                                <td valign="bottom" width={132}>
                                                    180x70mm{" "}
                                                </td>
                                                <td valign="bottom" width={96}>
                                                    three{" "}
                                                </td>
                                                <td valign="bottom" width={72}>
                                                    open
                                                </td>
                                                <td valign="bottom" width={109}>
                                                    <a
                                                        href="#"
                                                        onclick="MM_openBrWindow('mtm/MLM-100T-L.htm','','width=500,height=450')"
                                                    >
                                                        MLM 100T-L{" "}
                                                    </a>
                                                </td>
                                            </tr>
                                            <tr className="text">
                                                <td valign="bottom" width={72}>
                                                    21
                                                </td>
                                                <td valign="bottom" width={132}>
                                                    75x110mm{" "}
                                                </td>
                                                <td valign="bottom" width={96}>
                                                    four
                                                </td>
                                                <td valign="bottom" width={72}>
                                                    open{" "}
                                                </td>
                                                <td valign="bottom" width={109}>
                                                    <a
                                                        href="#"
                                                        onclick="MM_openBrWindow('mtm/MLM-100 F-L.htm','','width=500,height=525')"
                                                    >
                                                        MLM 100F-L{" "}
                                                    </a>
                                                </td>
                                            </tr>
                                            <tr className="text">
                                                <td valign="bottom">&nbsp;</td>
                                                <td valign="bottom">&nbsp;</td>
                                                <td valign="bottom">&nbsp;</td>
                                                <td valign="bottom">&nbsp;</td>
                                                <td valign="bottom">&nbsp;</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <Footer />
            </>
        );
    }
}

export default Products;
