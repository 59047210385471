import React from "react";

const image1 = require("./../../images/customer/customer1.png");
const image2 = require("./../../images/customer/customer2.png");
const image3 = require("./../../images/customer/customer3.png");
const image4 = require("./../../images/customer/customer4.png");

class Services13 extends React.Component {
  render() {
    return (
      <>
        <div
          className='section-full p-t80 p-b40 bg-no-repeat bg-bottom-right bg-cover'
          style={{
            backgroundColor: "gray",
          }}
        >
          <div className='container'>
            <div className='section-content'>
              {/* TITLE START*/}
              <div className='section-head center wt-small-separator-outer text-center'>
                <h2>Our Customers</h2>
              </div>

              {/* TITLE END*/}
              <div className='row d-flex justify-content-center'>
                <div className='project-img-effect-8'>
                  {/* <img src={image1.default} alt='' />
                  <img src={image2.default} alt='' />
                  <img src={image3.default} alt='' />
                  <img src={image4.default} alt='' /> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default Services13;
