import React, { useEffect } from "react";
import { useRef } from "react";
import jQuery from "jquery";
import { userContext } from "../../context/UserContext";
import ImageMapper from "react-image-mapper";

function Slider1(props) {
    const context = React.useContext(userContext);
    const [bladeProducts, setBladeProducts] = React.useState([]);

    useEffect(() => {}, [context["products"]]);
    return (
        <>
            <div className="section-full p-t80 p-b80 bg-gray">
                <div className="section-content container-fluid">
                    <h2 className="text-center">
                        {context &&
                            context["pages-content"]?.home?.productsTitle}
                    </h2>
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-12 w-100 banner-cont">
                                {context[
                                    "pages-content"
                                ]?.home?.bannerLinks?.map((item) => {
                                    console.log(item);
                                    return (
                                        <>
                                            <div className="banner-vert-text-cont">
                                                <a
                                                    className="banner-vert-text"
                                                    href={`/${item.link}`}
                                                >
                                                    {item.text}
                                                </a>
                                            </div>
                                            <div>
                                                <a href={`/${item.link}`}>
                                                    <img
                                                        src={item.image}
                                                        className="banner-img"
                                                    ></img>
                                                </a>
                                            </div>
                                        </>
                                    );
                                })}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default Slider1;
