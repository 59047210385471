import React, { useEffect, useState } from "react";
import { BrowserRouter, Route, Link, useParams } from "react-router-dom";

import Header from "../Parts/Header";
import Footer from "../Parts/Footer";
import Banner from "../Segments/Banner";
import ImageGallery from "react-image-gallery";
import "../../../node_modules/react-image-gallery/styles/css/image-gallery.css";
import { userContext } from "../../context/UserContext";
import ProductDetail from "./ProductDetail";
import _ from "lodash";

function Composite(props) {
    const context = React.useContext(userContext);
    const product = context?.products?.find(
        (item) => item.key == props.match.params.key
    );

    function shortenString(s, num) {
        if (s.length <= num) {
            return s;
        } else {
            return s.slice(0, num) + "...";
        }
    }
    return (
        <>
            <Header />
            <div className="page-content">
                <Banner title={product?.title} pagename={product?.title} />
            </div>
            {product && product.type === "product" && (
                <>
                    <div className="section-full p-t80 p-b80">
                        <div className="container woo-entry">
                            <div className="row m-b30">
                                <div className="col-lg-3 col-md-4  m-b30">
                                    <div className="wt-box wt-product-gallery on-show-slider">
                                        {product?.gallery && (
                                            <ImageGallery
                                                renderLeftNav={(
                                                    onClick,
                                                    disabled
                                                ) => {
                                                    return (
                                                        <button
                                                            className="image-gallery-icon image-gallery-left-nav owl-prev"
                                                            onClick={onClick}
                                                        >
                                                            <i class="fa fa-chevron-left"></i>
                                                        </button>
                                                    );
                                                }}
                                                renderRightNav={(
                                                    onClick,
                                                    disabled
                                                ) => {
                                                    return (
                                                        <button
                                                            className="image-gallery-icon image-gallery-right-nav owl-next"
                                                            onClick={onClick}
                                                        >
                                                            <i class="fa fa-chevron-right"></i>
                                                        </button>
                                                    );
                                                }}
                                                items={product?.gallery?.map(
                                                    (item) => ({
                                                        original: item,
                                                        thumbnail: item,
                                                    })
                                                )}
                                                showPlayButton={false}
                                            />
                                        )}
                                    </div>
                                </div>
                                <div className="col-lg-9 col-md-8">
                                    <div className="product-detail-info bg-gray p-a30">
                                        <div className="wt-product-title ">
                                            <h2 className="post-title">
                                                {product?.title}
                                            </h2>
                                        </div>
                                        <div className="wt-product-text">
                                            <p
                                                dangerouslySetInnerHTML={{
                                                    __html:
                                                        product?.extendedDescription ||
                                                        product?.description ||
                                                        "",
                                                }}
                                            ></p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-12">
                                    {product?.attributes && (
                                        <div className="wt-tabs border bg-tabs">
                                            <h3>Specifications</h3>
                                            <div>
                                                <table className="table table-striped m-b0 no-border">
                                                    <tbody>
                                                        {Object.entries(
                                                            product?.attributes
                                                        ).map(
                                                            ([key, value]) => (
                                                                <tr key={key}>
                                                                    <th>
                                                                        {key}
                                                                    </th>
                                                                    <td>
                                                                        {value}
                                                                    </td>
                                                                </tr>
                                                            )
                                                        )}
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </>
            )}
            {product && product.type === "product-group" && (
                <>
                    <div className="mt-5 mb-5 container">
                        <div className="masonry-wrap mfp-gallery clearfix d-flex justify-content-center flex-wrap">
                            {context["products"] &&
                                product?.children
                                    ?.map((item) => {
                                        return context?.products?.find(
                                            (p) => p.key == item
                                        );
                                    })
                                    .map((item, index) => {
                                        if (!item) return <>missing</>;
                                        return (
                                            <div className="masonry-item col-lg-4 col-md-6 col-sm-6 mb-5">
                                                <div className="text-center border h-100 d-flex flex-column">
                                                    <Link to={`/${item.key}/`}>
                                                        <img
                                                            className="mb-3 p-3 shadow"
                                                            src={
                                                                item?.gallery
                                                                    ?.length > 0
                                                                    ? item
                                                                          ?.gallery?.[0]
                                                                    : "assets/images/header/logo.png"
                                                            }
                                                            alt=""
                                                            onError={(e) => {
                                                                e.target.src =
                                                                    "../assets/images/machine/image-coming-soon.png";
                                                            }}
                                                        />
                                                    </Link>

                                                    <div className="mt-auto mx-4 mb-4">
                                                        <h3 className="wt-tilte m-b10 m-t0">
                                                            {item.title}
                                                        </h3>
                                                        {item?.description && (
                                                            <p>
                                                                {shortenString(
                                                                    item.description,
                                                                    100
                                                                )}
                                                            </p>
                                                        )}
                                                        {item?.children
                                                            ?.length > 0 && (
                                                            <Link
                                                                to={`/${item.key}/`}
                                                                className="site-button-link"
                                                            >
                                                                Know More...
                                                            </Link>
                                                        )}
                                                        {item?.hasDetail && (
                                                            <Link
                                                                to={`/${item.key}/`}
                                                                className="site-button-link"
                                                            >
                                                                Know More...
                                                            </Link>
                                                        )}
                                                    </div>
                                                </div>
                                            </div>
                                        );
                                    })}
                        </div>
                    </div>
                </>
            )}
            <BrowserRouter basename={props.match.params.key}>
                <Route path={`/:key/*`} element={<Composite />} />
            </BrowserRouter>
            <Footer />
        </>
    );
}

export default Composite;
