import React from "react";
import Header from "../Parts/Header";
import Footer from "../Parts/Footer";
import Slider1 from "../Segments/Slider1";
import Services1 from "../Segments/Services1";
import Testimonials1 from "../Segments/Testimonials1";
import HeroVideo from "../Segments/HeroVideo";

function Home(props) {
    return (
        <div>
            <>
                <Header />
                <div className="page-content">
                    <HeroVideo></HeroVideo>
                </div>
                <Slider1 />
                <Services1 />

                <Testimonials1 />

                <Footer />
            </>
        </div>
    );
}

export default Home;
