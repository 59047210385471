import React from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import Home from "./Pages/Home";
import AboutUs from "./Pages/AboutUs";
import PrivacyPolicy from "./Pages/PrivacyPolicy";
import Products from "./Pages/Products";
import ProductDetail from "./Pages/ProductDetail";
import Error403 from "./Pages/Error403";
import Error404 from "./Pages/Error404";
import Error405 from "./Pages/Error405";
import ScrollToTop from "./ScrollToTop";
import StandardMachines from "./Pages/StandardMachines";
import Test from "./Pages/Test";
import SpecialMachines from "./Pages/SpecialMachines";
import LaserMarkingMachines from "./Pages/LaserMarkingMachines";
import Consumables from "./Pages/Consumables";
import AllPrintedProducts from "./Pages/AllPrintedProducts";
import Composite from "./Pages/Composite";

function Components(props) {
    return (
        <BrowserRouter basename="/">
            <div className="page-wraper">
                <ScrollToTop />
                <Switch>
                    <Route path="/test" exact component={Test} />
                    <Route path="/" exact component={Home} />

                    {/* <Route
                        path="/laser-marking-machines"
                        exact
                        component={LaserMarkingMachines}
                    />
                    <Route
                        path="/standard-machines"
                        exact
                        component={StandardMachines}
                    />
                    <Route
                        path="/special-machines"
                        exact
                        component={SpecialMachines}
                    />
                    <Route
                        path="/consumables-accessories"
                        exact
                        component={Consumables}
                    />
                    <Route
                        path="/all-printed-products"
                        exact
                        component={AllPrintedProducts}
                    /> */}
                    {/* <Route path="/about-us" exact component={AboutUs} />
                    <Route
                        path="/privacy-policy"
                        exact
                        component={PrivacyPolicy}
                    />
                    <Route path="/error404" exact component={Error404} />

                    <Route component={Error404} /> */}
                </Switch>
            </div>
        </BrowserRouter>
    );
}

export default Components;
