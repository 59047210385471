import React from "react";
import Header from "../Parts/Header";
import Banner from "../Segments/Banner";
import { userContext } from "../../context/UserContext";
import Footer from "../Parts/Footer";
import AboutUsSegment from "../Segments/AboutUsSegment";
import Services13 from "../Segments/Services13";

class PrivacyPolicy extends React.Component {
  static contextType = userContext;

  render() {
    return (
      <>
        <Header />
        <div className='page-content'>
          <Banner
            title='Privacy Policy'
            pagename='Privacy Policy'
            bgimage={"https://picsum.photos/1920/500"}
          />
          <div className='container mt-5'>
            <div
              dangerouslySetInnerHTML={{
                __html:
                  this.context &&
                  this.context["pages-content"].privacyPolicy.page1,
              }}
            />

            <div
              dangerouslySetInnerHTML={{
                __html:
                  this.context &&
                  this.context["pages-content"].privacyPolicy.page2,
              }}
            />
          </div>
        </div>
        <Footer />
      </>
    );
  }
}

export default PrivacyPolicy;
